@import "src/assets/shared/scss/variables";

@mixin button-color(
    $backgroundColor,
    $hoverBackgroundColor,
    $disabledBackgroundColor,
    $textColor: var(--color-white),
    $disabledTextColor: var(--color-white),
    $borderColor: transparent,
    $disabledBoarderColor: transparent,
) {
    background-color: transparent;
    border-color: $borderColor;
    color: $textColor;
    overflow: hidden;
    position: relative;
    z-index: 1;

    &:hover {
        border-color: $hoverBackgroundColor;
    }

    // Active click state styling
    &:before {
        background-color: $disabledBackgroundColor;
        border-radius: 50%;
        box-sizing: border-box;
        content: '';
        height: 0;
        left: 50%;
        opacity: 0;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: height 500ms ease-in, width 500ms ease-in;
        width: 0;
        z-index: -1;
    }

    &:active {
        border-color: $disabledBackgroundColor;

        &:before {
            opacity: 1;
            height: 350px;
            width: 350px;
        }
    }

    &:after {
        content: '';
        background: $backgroundColor;
        border-color: $borderColor;
        box-sizing: border-box;
        opacity: 1;
        position: absolute;
        inset: 0;
        z-index: -2;
    }

    &:hover {
        &:after {
            background-color: $hoverBackgroundColor;
            border-color: $hoverBackgroundColor;
        }
    }

    &:disabled {
        background-color: $disabledBackgroundColor;
        border-color: $disabledBoarderColor;
        color: $disabledTextColor !important;
        font-style: normal;

        &:before {
            background: transparent;
        }

        &:after {
            background-color: $disabledBackgroundColor;
        }
    }
}

app-loading-button {
    + button,
    + app-loading-button,
    + app-split-action-button {
        margin-left: $menu-item-padding;
    }
}

button {
    border-radius: $button-border-radius;
    border: $input-border-width-style transparent;
    box-sizing: border-box;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-size: $font-size;
    height: $button-height;
    letter-spacing: 0.02em;
    line-height: 14px;
    min-width: 70px;
    padding: 8px 16px;

    &:disabled {
        cursor: not-allowed;
    }

    &:focus-visible {
        outline: var(--focus-styling);
    }

    // If two buttons are next to each other add spacing.
    + button.primary,
    + button.secondary,
    + button.tertiary,
    + button.white,
    + button.outline,
    + button.active,
    + button.disabled,
    + button.button-as-link,
    + app-loading-button,
    + app-split-action-button {
        margin-left: $menu-item-padding;
    }

    &.primary {
        @include button-color(
                var(--color-primary),
                var(--color-primary-hover),
                var(--color-primary-disabled),
                var(--color-white),
                var(--color-white),
                var(--color-primary),
                var(--color-primary-disabled)
        );
    }

    &.secondary {
        @include button-color(
                var(--color-secondary),
                var(--color-secondary-hover),
                var(--color-secondary-disabled),
                var(--color-white),
                var(--color-white),
                var(--color-secondary-hover),
                var(--color-secondary-disabled)
        );
    }

    &.outline {
        @include button-color(
                var(--color-white),
                var(--color-button-outline-hover),
                var(--color-white),
                var(--color-primary),
                var(--color-primary-disabled),
                var(--color-primary),
                var(--color-primary-disabled)
        );

        &:hover {
            border-color: var(--color-primary);
        }

        // Override active with outline
        &:active {
            border-color: var(--color-primary);

            &:before {
                border-color: var(--color-primary);
            }
        }

        &:disabled {
            &:active {
                border-color: var(--color-primary-disabled);

                &:before {
                    border-color: var(--color-primary-disabled);
                }
            }
        }

    }

    &.no-active-styling {
        &:before {
            background: transparent;
        }
    }

    &.notification {
        $clear-button-colour: rgba(255, 255, 255, 0.2);

        @include button-color(
                transparent,
                $clear-button-colour,
                transparent,
                var(--color-white),
                $clear-button-colour,
                var(--color-white),
                $clear-button-colour
        );
    }

    &.page-number {
        min-width: 30px;
    }

    &.copy-icon {
        background-color: transparent;
        background-image: url("/assets/shared/icons/copy.svg");
        background-position: center;
        background-repeat: no-repeat;
        filter: none;
        min-width: 20px;
        transition: 1s filter linear, 1s -webkit-filter linear;

        &:active {
            filter: invert(64%) sepia(0%) saturate(1%) hue-rotate(261deg) brightness(95%) contrast(92%) !important;
            transition: 0s filter linear, 0s -webkit-filter linear;
        }
    }

    // Used in the tables pagination.
    &.small {
        font-size: $font-size;
        height: 21px;
        min-width: 21px;
        padding: 0;
        width: 21px;
    }

    // Used in the tables pagination.
    &[class*="arrow"] {
        background-image: url("../../../assets/shared/icons/chevron-down_white.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 15px 15px;

        &[class*="-left"] {
            transform: rotate(90deg);
        }

        &[class*="-right"] {
            transform: rotate(-90deg);
        }

        &[class*="-up"] {
            transform: rotate(180deg);
        }
    }
}

button.routerLink {
    background: none;
    height: auto;
    min-width: auto;
    padding: 0;

    &:disabled,
    &:disabled:hover {
        background: none;
        color: var(--input-color-text-disabled);
        font-style: oblique;
    }
}

.button-as-link {
    background: none;
    border-radius: 0;
    color: var(--color-primary);
    cursor: pointer;
    font-family: $font-family;
    font-size: $font-size;
    font-weight: 400;
    height: auto;
    min-width: auto;
    padding: 0;
    position: relative;
    text-transform: none;

    &:active {
        background: none;
    }

    &:after {
        border-bottom: $input-border-width-style var(--color-primary);
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        width: 100%;
    }

    &.quinary {
        color: var(--color-quinary);

        &:after {
            border-bottom-color: var(--color-quinary);
        }
    }

    &:hover {
        &:after {
            border-bottom-width: 2px;
        }
    }


    &:disabled,
    &:disabled:hover {
        background: none;
        color: var(--input-color-text-disabled);

        &:after {
            border-bottom-color: var(--input-color-border-disabled);
            border-bottom-width: 1px;
        }
    }

    &.right-arrow {
        &:before {
            background-image: url("/assets/shared/icons/chevron-right-square.svg");
            content: "";
            height: 20px;
            width: 20px;
            position: absolute;
            right: -20px;
            top: -3px;
        }
    }

    &.primary {
        color: var(--color-primary);

        &:after {
            border-bottom: $input-border-width-style var(--color-primary);
        }
    }

    &.secondary {
        color: var(--color-secondary);

        &:after {
            border-bottom: $input-border-width-style var(--color-secondary);
        }
    }

    &.default {
        color: var(--input-color-text);

        &:after {
            border-bottom: $input-border-width-style var(--input-color-text);
        }
    }

    &.white {
        color: var(--color-white);

        &:after {
            border-bottom: $input-border-width-style var(--color-white);
        }
    }

    &:hover {
        &:after {
            border-bottom-width: 2px;
        }
    }
}

a {
    img {
        display: block; // Images are inline by default meaning they get spacing at the bottom, this helps with vertical alignment.
    }
}

// TODO work on media query sizes
@media only screen and (min-width: 1000px) {
    button {
        white-space: nowrap;
        width: auto;
    }
}
