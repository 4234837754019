@import 'src/assets/shared/scss/variables';
@import 'src/assets/shared/scss/mixins';

.ais-SearchBox {
    .ais-SearchBox-form {
        margin-bottom: 0;
        position: relative;

        .ais-SearchBox-submit {
            display: none;
        }

        .ais-SearchBox-reset {
            background: none;
            position: absolute;
            right: 0;

            .ais-SearchBox-resetIcon {
                display: none;
            }
        }
    }

    &.show-reset {
        .ais-SearchBox-reset {
            &:after {
                -webkit-appearance: none;
                border-radius: 10px;
                content: url('../icons/close.svg');
                cursor: pointer;
                position: relative;
            }
        }
    }
}

$searchHeight: 600px;
$searchWidth: 800px;
$articleHeight: 90px;

.ais-Hits--empty {
    display: none;
}

#hits.centralised_management {
    position: absolute;
    right: 0;

    .ais-Hits {
        left: -$searchWidth;
        position: absolute;
    }
}

.ais-Hits {
    position: absolute;
}

.ais-Hits-list {
    background: var(--color-container-background);
    height: $searchHeight;
    overflow: auto;
    padding-left: 0;
    padding-top: 16px;
    width: $searchWidth;

    @include header-drop-shadow(0.5);

    .ais-Hits-item {
        display: block;
    }
}

.hide {
    display: none;
}

article {
    a {
        align-items: center;
        box-sizing: border-box;
        display: flex;
        padding: 0 16px;

        &:-webkit-any-link {
            text-decoration: none;
        }

        &:before {
            display: none
        }

        &:focus,
        &:focus-visible {
            outline: none;
        }

        .article-content-left,
        .article-content-right {
            display: flex;
        }

        .article-content-left {
            align-items: flex-start;
            flex-direction: column;
            height: $articleHeight;
            justify-content: space-between;

            .title {

            }

            .content {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                font-size: 14px !important;
                line-clamp: 2;
                line-height: normal;
                margin: 0;
                max-height: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .tags {
                .bullet {
                    margin: 0 4px;
                }

                .tag-name {
                    color: var(--color-primary);
                    text-transform: capitalize;
                }
            }
        }

        $image-max-width: 140px;

        .article-content-right {
            justify-content: flex-end;
            margin-left: 32px;
            width: $image-max-width;

            .image-wrapper {
                img {
                    display: block;
                    height: auto;
                    max-height: $articleHeight;
                    max-width: $image-max-width;
                    width: auto;
                }

                .empty-image {
                    background: #e5e5e5;
                    display: block;
                    height: $articleHeight;
                    width: $image-max-width;
                }
            }
        }
    }
}
