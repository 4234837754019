@import 'src/assets/shared/scss/variables';
@import 'src/assets/shared/scss/color-filters-for-svgs';

$border: 1px solid var(--input-color-border);
$form-padding: 16px;

form {
    margin-bottom: 32px;
    max-width: $form-width;
    width: 100%;

    .button-as-link {
        display: inline-block;
    }

    &.full-width {
        max-width: 100%;
    }

    &.disabled {
        h2,
        h3 {
            color: var(--input-color-text-disabled);
        }

        label {
            color: var(--input-color-text-disabled);
            cursor: not-allowed !important;
            font-style: oblique;
        }
    }
}

.wizard-step {
    form {
        div[ng-reflect-name="floating_ip"] {
            max-width: 100%;
        }

        h2 {
            margin-top: 0;
            line-height: 32px;
        }
    }
}

app-lb-checkbox {
    .form-content {
        input + button {
            margin-right: 36px;
        }
    }
}

.form-table {
    max-width: 100%;
}

.form-group {
    margin-bottom: $form-padding;

    .tooltip-wrapper {
        align-items: center;
        display: flex;
        height: 20px;
        justify-content: center;
        width: 20px;

        .tooltip-trigger {
            color: var(--color-white);

            img {
                @extend %filter-primary;
            }
        }
    }
}

.form-vertical {
    .form-group + .form-group {
        margin-top: $form-padding;
    }
}

.form-content {
    align-items: center;
    display: flex;
    position: relative;

    app-tooltip,
    app-help {
        margin-left: auto;
    }

    input + button {
        margin-left: $form-padding;
    }
}

.form-row {
    display: flex;
    flex-wrap: wrap;

    > label {
        width: 100%;
    }

    .form-content + .form-content,
    .form-group + .form-group {
        padding-left: $form-padding;
    }

    // Make the first group's input 100% as it won't have a tooltip
    .form-group:first-of-type {
        input {
            max-width: 100%;
        }
    }

    .form-group {
        .form-content {
            input + app-dropdown {
                margin-left: 10px;
            }
        }
    }
}

h1 + form {
    margin-top: 31px;
}

// Style Form validation messaging both in forms and forms in tables.
.form-content,
.table-item-content {
    app-validation-messages {
        bottom: -10px;
        left: 0;
        position: absolute;
        right: 0;

        // styling lists within validation messages. Font size to match the rest of the text.
        ol, ul {
            font-size: 1em;
            line-height: 1.2em;
            padding-left: 16px;
        }

    }
}
