@import "../../../assets/shared/scss/animations";
@import "../../../assets/shared/scss/color-filters-for-svgs";
@import "../../../assets/shared/scss/mixins";
@import "../../../assets/shared/scss/variables";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


// Used in dropdown to hide items not searched for but can't use angular hidden as the item is display flex not block.
.hidden {
    display: none;
}

.small-screen-overlay {
    display: none;
}

app-loading-button {
    display: inline-block;
}

#header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $z-index-headers-menus;

    .header {
        background: var(--color-header-background);
        color: var(--header-text);
        display: flex;
        height: $mobile-header-height;
        justify-content: space-between;
        width: 100%;
        z-index: $z-index-headers-menus;

        @include header-drop-shadow();

        .header-block {
            align-items: center;
            display: flex;
            font-size: 14px;
            height: 100%;
            padding: 0 $menu-item-padding;

            .cluster-health {
                .health {
                    &.ok {
                        @extend %filter-color-alert-success;
                    }

                    &.warning {
                        @extend %filter-color-alert-warn;
                    }

                    &.error {
                        @extend %filter-color-alert-error;
                    }
                }

                .status {
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 26px;
                    right: 239px;

                    &.active {
                        background: var(--color-alert-success);
                    }

                    &.passive {
                        background: var(--color-alert-error);
                    }

                    &.unavailable {
                        background: var(--color-lightest-grey);
                    }
                }
            }

            &.page-title {
                display: none;
            }

            &.namespace {
                display: none;
            }

            &.products {
                display: none;

                .menu-item-wrapper:last-of-type {
                    border-bottom: none;
                }
            }

            &.search {
                display: none;
            }

            &.icons {
                > * {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    height: $input-height;
                    margin: 0 6px;
                    padding: 0 6px;
                }

                span img {
                    display: block;
                }
            }

            &.icons,
            &.user-details {
                display: none;
            }

            &.burger-menu {
                flex: 0 0 $small-header-item-width;
                font-size: 26px;
                justify-content: center;
            }
        }

        .namespace-products {
            align-items: center;
            display: flex;
        }
    }
}

#main-wrapper {
    background: var(--page-background);
    display: flex;
    flex-direction: column;
    height: calc(100% - #{$header-height});
    margin-top: $header-height;
    position: relative;
    width: 100%;
    z-index: 10;
    overflow-x: hidden;

    > div {
        height: 100%;
        width: 100%;
    }

    #main-content {
        display: flex;
        height: 100%;
        overflow: hidden;
        position: relative;

        > div {
            width: 100%;
            overflow-y: auto;
        }
    }
}

#page-wrapper {
    display: flex;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: $z-index-page-body;
}

app-dropdown-wrapper {
    width: 100%;
}

#content-wrapper {
    &:not(:has(app-table), :has(.display-code-wrap)),
    &:has(.wizard-wrapper) {
        height: 100%;
    }

    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: $z-index-page-body;

    .component-main {
        background-color: var(--color-white);
        box-sizing: border-box;
        height: calc(100% - #{$footer-component-height});
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 $component-main-padding $component-main-padding;
        position: relative;
        width: 100%;

        &.no-padding {
            height: 100%;
            padding: 0;
        }

        &.equal-padding {
            height: 100%;
            padding: 0 $component-main-padding $component-main-padding;
        }
    }

    .component-footer {
        align-items: center;
        border-top: $input-border-width-style var(--color-footer-border);
        box-sizing: border-box;
        display: flex;
        height: $footer-component-height;
        padding: 0 $component-main-padding;
    }
}

@media only screen and (max-width: 900px) and (orientation: landscape),
(min-width: 320px) and (max-width: 700px) {
    .small-screen-overlay {
        align-items: center;
        background: var(--color-white);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
        z-index: 9999;
    }
}

@media only screen and (min-width: 821px) {
    #header-wrapper {
        .header {
            .header-block {
                &.search {
                    display: flex;
                    margin-left: 7px;
                    margin-right: auto;
                    width: 300px;
                }
            }
        }
    }
}

/* iPad Pro (portrait) ---------------- */
@media only screen and (min-width: 701px) and (max-width: 1222px) {
    #header-wrapper {
        .header {
            height: $mobile-header-height;

            .namespace-products {
                justify-content: end;
                width: 100%;

                .namespace {
                    display: flex;
                }
            }

            .header-block {
                &.page-title {
                    display: none;
                }

                &.logo-component {
                    flex: 0 0 $small-header-item-width;
                }

                &.icons,
                &.user-details {
                    display: none;
                }

                &.icons {
                    app-cm-header-notifications {
                        display: none;
                    }
                }

                &.burger-menu {
                    display: flex;
                    width: $small-header-item-width;
                    flex: 0 0 $small-header-item-width;
                }
            }
        }
    }

    #main-wrapper {
        height: calc(100% - #{$mobile-header-height});
        margin-top: $mobile-header-height;
    }

    #content-wrapper {
        .component-main {
            // Set the content how we want the first one
            > [class^="content"] {
                height: 96px; // <-- keep the header height the same as content changes.
                padding: 0;
            }

            // Revert change above to target all others.
            > [class^="content"] ~ [class^="content"] {
                height: auto;
                padding: 10px 0 20px 0;
            }
        }
    }
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1223px) {
    #header-wrapper {
        .header {
            height: $header-height;

            .header-block {
                &.logo-component {
                    padding: 0 16px;
                }

                &.page-title {
                    display: none;
                }

                &.namespace,
                &.products,
                &.search,
                &.user-details,
                &.icons {
                    display: flex;
                }

                &.search {
                    margin-left: 7px;
                    margin-right: auto;
                    width: 320px;
                }

                &.icons {
                    app-cm-header-notifications {
                        display: none;
                    }
                }

                &.burger-menu {
                    display: none;
                }
            }
        }
    }

    #main-wrapper {
        height: calc(100% - #{$header-height});
        left: 0;
        width: 100%;
    }

    #content-wrapper {
        .component-main {
            // Set the content how we want the first one
            > [class^="content"] {
                height: 96px; // <-- keep the header height the same as content changes.
                padding: 0;
            }

            // Revert change above to target all others.
            > [class^="content"] ~ [class^="content"] {
                height: auto;
                padding: 10px 0 20px 0;
            }
        }
    }
}

.license-model {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0 0 8px 8px;

    .lb-version {
        color: var(--menu-item-text-color);

        span {
            color: var(--color-tertiary);
        }
    }
}

.intercom-lightweight-app-launcher {
    background: var(--color-primary) !important;
    border: 1px solid var(--color-white);
}

.intercom-messenger-frame {
    box-shadow: 0 0 15px rgb(0 0 0 / 40%) !important
}
