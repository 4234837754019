$breakpoint-xs: 320px;
$breakpoint-s: 460px;
$breakpoint-m: 700px;
$breakpoint-ml: 1023px;
$breakpoint-l: 1440px;
$breakpoint-desktop: 1200px;

$header-height: 70px;
$mobile-header-height: 50px;
$left-item-width: 250px;
$small-header-item-width: 35px;

$footer-component-height: 96px;

// z-index
$z-index-page-body: 0;
$z-index-headers-menus: 12;
$z-index-tooltips: 21;
$z-index-validation-messages: 31;
$z-index-dropdown: 41;
$z-index-action-dots: 51;
$z-index-notifications: 61;
$z-index-modal-overlays: 71;
$z-index-burger-menu-drawer: 81;

// Menus
$menu-item-height: 52px;
$menu-item-padding: 16px;
$menu-title-height: 96px;
$menu-level-1-width: 250px;
$menu-level-2-width: 320px;

// Page
$component-main-padding: 32px;

// Typography
$font-family: 'Roboto', sans-serif;
$font-family-header: 'Roboto-Slab', serif;
$font-size: 14px;

// Inputs & buttons
$form-width: 436px;
$input-border-radius: 4px;
$input-height: 32px;
$input-label-padding: 16px;
$input-border-width-style: 1px solid;
$input-border-width-style-color: $input-border-width-style var(--input-color-border);
$input-search-width: 320px;
$button-height: 32px;
$button-padding: 16px;
$button-border-radius: $input-border-radius;

// Notifications
$notification-width: 400px;
$rightSpacing: 20px;
$animation-out: 450px; /* notification width plus spacing and a bit more! */

// Table
$table-page-padding-offset: $component-main-padding;

// Drawer
$drawer-width: 345px;
