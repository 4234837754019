@import 'src/assets/shared/scss/variables';

label.disabled {
    color: var(--input-color-label-disabled);
}

iframe {
    height: 100%;
    width: 100%;
    border: none;
}

input,
textarea {
    border-radius: $input-border-radius;
    border: $input-border-width-style var(--input-color-border);
    box-sizing: border-box;
    font-size: 14px;
    height: $input-height;
    padding: 8px 16px;
    width: 100%;

    &::placeholder {
        color: var(--color-lightest-grey);
    }

    &.ng-invalid.ng-touched {
        border-color: var(--input-color-border-focus) !important;

        &:focus,
        &:focus-visible {
            border-color: var(--input-color-border-focus) !important;
        }
    }

    &:focus-visible {
        border-color: var(--input-color-border-focus);
        outline: unset;
    }

    &:-webkit-autofill {
        background: var(--input-color-background-autofill);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 $input-height var(--input-color-background-autofill) inset !important;
    }

    &:disabled {
        background-color: var(--input-color-background-disabled);
        color: var(--input-color-text-disabled);
        cursor: not-allowed;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 $input-height var(--input-color-background-disabled) inset !important;
            -webkit-text-fill-color: var(--input-color-text-disabled);
        }
    }
}

input[type="checkbox"] {
    $checkbox-size: 20px;

    appearance: none;
    // stop firefox styling the checkbox incorrectly
    -moz-appearance: none;
    border: none;
    cursor: pointer;
    display: inline;
    height: calc($checkbox-size + 1px);
    margin: 0;
    max-width: $checkbox-size;
    padding: 0;
    position: relative;
    width: $checkbox-size;

    &.lb-checkbox {
        &:focus {
            outline: var(--focus-styling);
        }

        &:before {
            position: absolute;
            content: url('../icons/checkbox-unchecked.svg');
            height: $checkbox-size;
            width: $checkbox-size;
        }

        &:checked {
            &:before {
                content: url('../icons/checkbox-checked.svg');
            }
        }

        &.partial {
            &:before {
                content: url('../icons/checkbox-partial.svg');
            }
        }

        &:disabled {
            &:before {
                content: url('../icons/checkbox-disabled.svg');
                cursor: not-allowed;
            }

            &:checked {
                &:before {
                    content: url('../icons/checkbox-disabled-checked.svg');
                }
            }

            &.partial {
                &:before {
                    content: url('../icons/checkbox-disabled-partial.svg');
                }
            }
        }

        &.ng-invalid.ng-touched {
            border: var(--input-color-border);
        }

        &:focus,
        &:focus-visible {
            outline-color: var(--input-color-border);
        }
    }

    + label {
        cursor: pointer;
        font-weight: 400;
        margin-left: $input-label-padding;
        margin-bottom: 0;
    }

    &:disabled + label {
        color: var(--input-color-text-disabled);
        cursor: not-allowed;
    }
}

/*
Boiler plating for toggle switch.
    <input type="checkbox" id="switch" class="toggle-switch">
    <label for="switch"></label>
All the styling is on the label and the label must be after the input...
 */
input[type="checkbox"].toggle-switch {
    display: none !important;

    $height: 20px;
    $width: 20px;

    + label {
        background: var(--color-primary);
        border-radius: $width;
        border: 2px solid var(--color-primary);
        height: $height;
        margin: 0 8px !important;
        position: relative;
        width: calc($width * 2);

        &:before {
            content: '';
            background: var(--color-white);
            border-radius: $width;
            width: $width;
            height: $height;
            position: absolute;
            top: 0;
            left: 0;
            transition: left 0.2s;
        }
    }

    &:checked + label {
        background: var(--color-lighter-grey);
        border-color: var(--color-lighter-grey);

        &:before {
            left: $width;
        }
    }
}

%radio-content {
    border-radius: 100%;
    content: '';
    position: absolute;
}

[type="radio"] {
    align-items: center;
    display: flex;
    height: 20px;
    justify-content: center;
    margin: 0 8px 0 0;
    position: relative;
    width: 20px;

    + label {
        margin: 0;
        padding-right: 24px;
    }

    &:before {
        @extend %radio-content;
        background: var(--color-container-background);
        border: 1px solid var(--color-main-grey);
        height: 20px;
        width: 20px;
    }

    &:checked {
        &:after {
            @extend %radio-content;
            background: var(--color-main-grey);
            height: 10px;
            width: 10px;
        }
    }

    &:focus,
    &:focus-visible {
        &:before {
            outline: var(--focus-styling);
        }
    }

    &:disabled {
        &:before {
            border: 1px solid var(--color-lighter-grey);
        }

        &:after {
            background: var(--color-lighter-grey);
        }
    }
}

input[type="time"] {
    width: 170px;
}

input[type="date"] {
    min-width: 150px;
}

app-dropdown[formcontrolname^="mode"] {
    width: 150px;
}

input[id^="port"],
input[id*="port"],
input[name^="port"],
input[name*="port"],
input[class^="port"],
input[class*="port"] {
    margin-right: $input-label-padding;
    width: 100px;
}

input[type="search"] {
    background-image: url('../icons/search.svg');
    background-position: 16px center;
    background-repeat: no-repeat;
    padding-left: 40px;
    width: $input-search-width;

    &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        border-radius: 10px;
        content: url('../icons/close.svg');
        cursor: pointer;
        position: relative;
    }
}

textarea {
    height: 85px;
}

.form-group {
    // Reduce input width to allow for help icon.
    app-dropdown,
    input:not([type="checkbox"]),
    textarea {
        max-width: calc(100% - 36px);
    }

    .form-content > label.lb-checkbox {
        font-weight: 400;
        max-width: fit-content;
        margin-right: 10px;
    }
}

form.no-help {
    // Reduce input width to allow for help icon.
    app-dropdown,
    input:not([type="checkbox"]),
    textarea {
        max-width: 100%;
    }

    app-loading-button {
        max-width: 100%;
        width: 100%;
    }
}
