%animation {
    &.jiggle .widget-item {
        animation-delay: -.75s;
        animation-duration: .25s;

        &:nth-child(odd) {
            animation-name: keyframes1;
            animation-iteration-count: infinite;
            transform-origin: 50% 10%;
        }

        &:nth-child(even) {
            animation-name: keyframes2;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            transform-origin: 30% 5%;
        }
    }

    @keyframes keyframes1 {
        0% {
            transform: rotate(-1deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(1.5deg);
            animation-timing-function: ease-out;
        }
    }

    @keyframes keyframes2 {
        0% {
            transform: rotate(1deg);
            animation-timing-function: ease-in;
        }

        50% {
            transform: rotate(-1.5deg);
            animation-timing-function: ease-out;
        }
    }
}

$tablet-width: 768px;
$desktop-width: 1110px;
$gutter: 15px;

.widgetContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: $gutter;
    grid-auto-rows: minmax(75px, auto);
    grid-auto-flow: dense;

    @extend %animation;

    .widget-item {
        @for $number from 1 through 4 {
            &.col-span-#{$number} {
                grid-column-end: span $number;
            }

            &.row-span-#{$number} {
                grid-row-end: span $number;
            }
        }
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 769px) {
    .widgetContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .widget-item {
        @for $number from 1 through 4 {
            @if $number > 2 {
                &.col-span-#{$number} {
                    grid-column-end: span $number * 0.5 !important;
                }
            } @else {
                &.col-span-#{$number} {
                    grid-column-end: span $number;
                }
            }

            @if $number < 4 {
                &.row-span-#{$number} {
                    grid-column-end: span $number;
                }
            } @else {
                &.row-span-#{$number} {
                    grid-row-end: span $number * 2 !important;
                }
            }

        }
    }
}
