@import "variables";
@import "color-filters-for-svgs";

@mixin menu-header($prefix-image-filter, $text-color, $background-color, $background-image-filter, $divider-color) {
    align-items: center;
    background-color: $background-color;
    border-bottom: 1px solid $divider-color;
    box-sizing: border-box;
    display: flex;
    height: $menu-title-height;
    padding-left: $menu-item-padding;
    position: relative;

    .menu-toggle {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: $menu-item-height;
        justify-content: center;
        margin-left: -$menu-item-padding;
        margin-right: 2px;
        min-width: $menu-item-height;
        width: $menu-item-height;
    }

    .menu-image-wrapper {
        align-items: center;
        display: flex;
        height: $menu-item-height;
        justify-content: center;
        margin-left: -$menu-item-padding;
        min-width: $menu-item-height;
        width: $menu-item-height;

        .menu-title-image {
            height: 25px;
            width: 25px;

            @extend %#{$prefix-image-filter};
        }
    }

    .menu-titles {
        display: flex;
        flex-direction: column;
        max-width: 90%;
        z-index: $z-index-page-body + 1;

        .menu-title {
            color: $text-color;
            margin-bottom: 0;
            overflow: hidden;
            padding-right: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .sub-title {
            color: $text-color;
            font-family: $font-family;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
        }
    }

    .background-image-wrapper {
        height: 100%;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-align: right;
        width: 170px;
        z-index: $z-index-page-body;

        img {
            height: 150%;
            margin-right: -15px;
            margin-top: -15%;

            @extend %#{$background-image-filter};
        }
    }
}

@mixin menu-item-wrapper(
    $text-color,
    $background-color,
    $filter-color,
    $text-color-active,
    $background-color-active,
    $filter-color-active,
    $text-color-hover,
    $background-color-hover,
    $filter-color-hover,
    $divider-color,
) {
    align-items: center;
    background: $background-color;
    border-bottom: 1px solid $divider-color;
    box-sizing: border-box;
    color: $text-color;
    cursor: pointer;
    display: flex;
    height: $menu-item-height;
    padding-left: $menu-item-padding;
    padding-right: $menu-item-padding;
    position: relative;
    width: 100%;

    .menu-image-wrapper {
        align-items: center;
        display: flex;
        height: $menu-item-height;
        justify-content: center;
        margin-left: -$menu-item-padding;
        min-width: $menu-item-height;
        width: $menu-item-height;

        img {
            height: 20px;
            width: 20px;

            @extend %#{$filter-color};
        }
    }

    span {
        color: $text-color;
        font-family: $font-family;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .menu-item-link {
        margin-left: auto;
        @extend %filter-color-white;
    }

    .menu-item-subscription {
        margin-left: auto;
        filter: none;
    }

    .menu-item-disabled {
        margin-left: auto;
    }

    &.chevron-right,
    &.chevron-down {
        &:after {
            content: '';
            background-repeat: no-repeat;
            background-position: center;
            height: $menu-item-height;
            position: absolute;
            right: 0;
            width: calc($menu-item-height * 0.5);

            @extend %#{$filter-color};
        }
    }

    &.chevron-right {
        &:after {
            background-image: url("../icons/chevron-right.svg");
        }
    }

    &.chevron-down {
        &:after {
            background-image: url("../icons/chevron-down.svg");
        }
    }

    &.active {
        background: $background-color-active;

        span {
            color: $text-color-active;
        }

        .menu-image-wrapper {
            img {
                @extend %#{$filter-color-active};
            }
        }

        &.chevron-right {
            &:after {
                @extend %#{$filter-color-active};
            }
        }
    }

    &:hover:not(.disabled):not(.coming-soon) {
        background: $background-color-hover;

        span {
            color: $text-color-hover;
        }

        .menu-image-wrapper {
            img {
                @extend %#{$filter-color-hover};
            }
        }

        &.chevron-right {
            &:after {
                @extend %#{$filter-color-hover};
            }
        }
    }

    &.disabled {
        cursor: not-allowed;

        span {
            color: var(--text-color-inactive);
        }

        img {
            @extend %filter-disabled;
        }

    }

    &.coming-soon {
        cursor: not-allowed;

        span {
            color: var(--text-color-inactive);
        }

        .menu-image-wrapper {
            img {
                @extend %filter-disabled;
            }
        }

        img {
            @extend %filter-disabled;
        }

        &:after {
            background: inherit;
            color: var(--text-color-inactive) !important;
            content: "Coming Soon" !important;
            filter: none;
            font-family: $font-family;
            font-size: 11px;
            font-style: italic;
            padding: 0 0 0 8px;
            position: absolute;
            right: 15px;
            top: 41%;
        }

        .menu-item-link {
            display: none;
        }

        &:hover {
            background: $background-color;

            .menu-image-wrapper {
                img {
                    @extend %filter-disabled;
                }
            }
        }
    }

    &.action-dots-small {
        height: 40px;

        .button-inner {
            justify-content: flex-start !important;
            padding-left: $menu-item-padding;
        }
    }
}

:root {
    position: relative;
}

.menu-header {
    @include menu-header(
            filter-color-white,
            var(--color-white),
            var(--menu-color-background-level-1),
            filter-color-mid-grey,
            var(--menu-color-background-level-1-divider)
    )
}

// This is outside of the .menu class so the '.menu-item-wrapper' class can be used easily across the application.
.menu-item-wrapper {
    @include menu-item-wrapper(
            var(--color-white),
            var(--menu-color-background-level-1),
            filter-color-white,
            var(--color-white),
            var(--menu-color-background-level-1-active),
            filter-color-white,
            var(--color-white),
            var(--menu-color-background-level-1-hover),
            filter-color-white,
            var(--menu-color-background-level-1-divider)
    );

    &:disabled,
    &.disabled {
        @include menu-item-wrapper(
                var(--text-color-inactive),
                var(--menu-color-background-level-1),
                filter-color-white,
                var(--text-color-inactive),
                var(--menu-color-background-level-1),
                filter-disabled,
                var(--text-color-inactive),
                var(--menu-color-background-level-1-hover),
                filter-color-white,
                var(--menu-color-background-level-1-divider)
        );

        cursor: not-allowed;
    }
}

.menu {
    background: var(--menu-color-background-level-1);
    overflow: hidden;

    .level-1 {
        .menu-header {
            @include menu-header(
                    filter-color-white,
                    var(--color-white),
                    var(--menu-color-background-level-1),
                    filter-primary,
                    var(--menu-color-background-level-1-divider)
            );
        }

        .menu-items {
            background: var(--menu-color-background-level-1);
        }

        .menu-item-wrapper {
            @include menu-item-wrapper(
                    var(--color-white),
                    var(--menu-color-background-level-1),
                    filter-color-white,
                    var(--color-white),
                    var(--menu-color-background-level-1-active),
                    filter-color-white,
                    var(--white-colour),
                    var(--menu-color-background-level-1-hover),
                    filter-color-white,
                    var(--menu-color-background-level-1-divider)
            );

            &:disabled,
            &.disabled {
                @include menu-item-wrapper(
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--text-color-inactive)
                );

                cursor: not-allowed;
            }
        }
    }

    .level-2 {
        .menu-header {
            @include menu-header(
                    filter-color-white,
                    var(--color-white),
                    var(--menu-color-background-level-2),
                    filter-primary,
                    var(--menu-color-background-level-2-divider)
            );
        }

        .menu-items {
            background: var(--menu-color-background-level-2);
        }

        .menu-item-wrapper {
            @include menu-item-wrapper(
                    var(--color-white),
                    var(--menu-color-background-level-2),
                    filter-color-white,
                    var(--color-white),
                    var(--menu-color-background-level-2-active),
                    filter-color-white,
                    var(--white-colour),
                    var(--menu-color-background-level-2-hover),
                    filter-color-white,
                    var(--menu-color-background-level-2-divider)
            );

            &:disabled,
            &.disabled {
                @include menu-item-wrapper(
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--menu-color-background-level-2-divider)
                );

                cursor: not-allowed;
            }
        }

        // Offset because of drop-shadow on level 1.
        .menu-header,
        .menu-item-wrapper {
            padding-left: calc(#{$menu-item-padding} + #{$menu-item-padding * 0.5});
        }

        .level-1,
        .level-1 div {
            .menu-item-wrapper {
                @include menu-item-wrapper(
                        var(--color-main-grey),
                        var(--color-white),
                        filter-color-main-grey,
                        var(--color-white),
                        var(--color-primary),
                        filter-color-white,
                        var(--white-colour),
                        var(--menu-color-background-level-2-hover),
                        filter-color-white,
                        var(--menu-color-background-level-1-divider)
                );
            }
        }
    }

    .level-3,
    .level-3 div {
        .menu-header {
            @include menu-header(
                    filter-color-main-grey,
                    var(--color-white),
                    var(--menu-color-background-level-3),
                    filter-primary,
                    var(--menu-color-background-level-3-divider)
            );
        }

        .menu-items {
            background: var(--menu-color-background-level-3);
        }

        .menu-item-wrapper {
            @include menu-item-wrapper(
                    var(--color-white),
                    var(--menu-color-background-level-3),
                    filter-color-main-grey,
                    var(--color-white),
                    var(--menu-color-background-level-3-active),
                    filter-color-white,
                    var(--color-white),
                    var(--menu-color-background-level-3-hover),
                    filter-color-main-grey,
                    var(--menu-color-background-level-3-divider)
            );

            &:disabled,
            &.disabled {
                @include menu-item-wrapper(
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--text-color-inactive),
                        var(--color-white),
                        filter-disabled,
                        var(--color-white),
                        var(--menu-color-background-level-3-hover),
                        filter-disabled,
                        var(--menu-color-background-level-3-divider)
                );

                cursor: not-allowed;
            }

            padding-left: 48px !important;
        }

        // Offset because of drop-shadow on level 1.
        .menu-header,
        .menu-item-wrapper {
            padding-left: calc(#{$menu-item-padding} + #{$menu-item-padding * 0.5});
        }
    }

    .menu-item-wrapper {
        &:after {
            opacity: 1;
            transition: opacity 250ms ease 100ms;
        }
    }

    &.closed {
        .menu-item-wrapper {
            &:after {
                opacity: 0;
            }
        }
    }
}

@media only screen and (min-width: 701px) and (max-width: 1223px) {
    .menu-item-wrapper.secondary {
        padding-left: 50px;
        background: var(--menu-color-background-level-2);

        &.active {
            background: var(--menu-color-background-level-1-active);
        }

        &:hover {
            background: var(--menu-color-background-level-2-hover);
        }

        &.sub-item {
            padding-left: 70px;
        }
    }
}
