@import 'src/assets/shared/scss/animations';

@mixin header-drop-shadow($opacity: 0.25) {
    box-shadow: 0 1px 5px rgba(0, 0, 0, $opacity);
}

@mixin menu-drop-shadow($opacity: 0.2) {
    box-shadow: 3px 0 5px rgba(0, 0, 0, $opacity);
}

@mixin status-bullet($bullet-size: 10px) {
    border-radius: $bullet-size;
    display: inline-block;
    height: $bullet-size;
    margin-right: 10px;
    width: $bullet-size;

    &.passive, &.online, &.active, &.enabled {
        background: var(--color-status-online);
    }

    &.offline, &.down {
        background: var(--color-status-offline);
    }

    &.error {
        background: var(--color-tertiary);
    }

    &.info, &.maint, &.maintenance {
        background: var(--color-alert-info);
    }

    &.warn {
        background: var(--color-alert-warn);
    }

    &.pending {
        background: var(--color-tertiary);
    }

    &.none, &.unavailable, &.disabled, &.inactive {
        border: 1px solid var(--text-color-inactive);
    }
}

@mixin attention-marker($marker-size: 8px) {
    background: var(--color-tertiary);
    border: 2px solid var(--color-white);
    border-radius: $marker-size;
    bottom: -4px;
    height: $marker-size;
    position: absolute;
    right: -3px;
    width: $marker-size;
}

@mixin notification-trimmed($line-clamp, $maxWidth: 280px) {
    display: -webkit-box;
    max-width: $maxWidth;
    overflow: hidden;
    overflow-wrap: break-word;
    position: relative;
    -webkit-line-clamp: $line-clamp;
    -webkit-box-orient: vertical;
}

@mixin invert-scroll-bar-color($width: 8px, $color: 'grey') {
    $track-color: var(--color-main-grey);
    $thumb-color: var(--color-light-grey);
    $thumb-hover: var(--color-lighter-grey);

    @if ($color == 'primary') {
        $track-color: var(--color-primary-active);
        $thumb-color: var(--color-primary-hover);
        $thumb-hover: var(--color-primary-disabled)
    }

    @if ($color == 'menu-level-1') {
        $track-color: var(--menu-color-background-level-1);
        $thumb-color: var(--menu-color-background-level-1-active);
        $thumb-hover: var(--menu-color-background-level-1-hover)
    }

    @if ($color == 'menu-level-2') {
        $track-color: var(--menu-color-background-level-2);
        $thumb-color: var(--menu-color-background-level-2-active);
        $thumb-hover: var(--menu-color-background-level-2-hover)
    }

    &::-webkit-scrollbar {
        width: $width;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: $track-color;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $thumb-color;
        border-radius: 5px
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $thumb-hover;
    }
}

@mixin avatar($size: 50px, $fontSize: 20px) {
    .avatar {
        align-items: center;
        border-radius: 50%;
        display: flex;
        height: $size;
        justify-content: center;
        width: $size;
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.2);
        margin-bottom: 26px;

        span {
            background: inherit;
            background-clip: text;
            color: transparent;
            filter: invert(1) grayscale(1) contrast(9);
            font-size: $fontSize;
        }
    }
}

@mixin loading-indicator($size: 30px, $color: #7633b0) {
    $border-width: round(max(2px, $size / 6)); // Set the min width to 2px and round the number to stop getting half pixels.
    content: '';
    border-radius: 50%;
    position: relative;
    border-color: $color #aa80ce #aa80ce #aa80ce;
    border-style: solid;
    border-width: $border-width;
    height: $size;
    width: $size;
    display: block;

    @include spin-this();

    &:before {
        content: "";
        position: absolute;
        display: block;
        height: $size;
        width: $size;
        border-color: $color transparent transparent transparent;
        border-width: $border-width;
        border-style: solid;
        border-radius: 50%;
        right: -$border-width;
        top: -$border-width;
        transform: rotate(67deg);
    }
}
