@import 'src/assets/shared/scss/mixins';

app-dropdown-wrapper {
    .drop-content {
        &.down:not(.invisible) {
            box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);

            .search-wrapper {
                background: var(--menu-color-background-level-1);
                border-color: var(--menu-color-background-level-1);
                border-radius: 4px 4px 0 0;
            }
        }

        .search-wrapper {
            border-color: var(--menu-color-background-level-1);
        }

    }

    .external-drop-container {
        width: 100% !important;

        .menu-item-wrapper:last-of-type {
            border-bottom: none;
        }
    }

    .drop-container-html {
        max-height: 260px;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        border-radius: 4px;
        background: var(--menu-color-background-level-1);

        @include invert-scroll-bar-color();

        .dropItem {
            &.search {
                background-color: var(--color-white);
            }
        }
    }
}

.local-button {
    height: 32px;
    justify-content: center;

    img {
        margin-left: 8px;
    }
}
