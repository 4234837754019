// Generated using https://isotropic.co/tool/hex-color-to-css-filter/
%filter-black {
    filter: brightness(0) invert(0);
}

%filter-primary {
    filter: invert(12%) sepia(96%) saturate(6468%) hue-rotate(275deg) brightness(62%) contrast(113%);
}

%filter-secondary {
    filter: invert(12%) sepia(81%) saturate(6800%) hue-rotate(282deg) brightness(109%) contrast(122%);
}

%filter-tertiary {
    filter: invert(13%) sepia(97%) saturate(5393%) hue-rotate(317deg) brightness(109%) contrast(107%);
}

%filter-quaternary {
    filter: invert(76%) sepia(84%) saturate(1699%) hue-rotate(107deg) brightness(108%) contrast(102%);
}

%filter-quinary {
    filter: invert(34%) sepia(57%) saturate(2650%) hue-rotate(226deg) brightness(90%) contrast(82%);
}

%filter-hexadic {
    filter: invert(89%) sepia(76%) saturate(4505%) hue-rotate(351deg) brightness(95%) contrast(108%);
}

// Greys
%filter-color-main-grey {
    filter: invert(14%) sepia(61%) saturate(0%) hue-rotate(146deg) brightness(106%) contrast(92%);
}

%filter-color-mid-grey {
    filter: invert(26%) sepia(0%) saturate(112%) hue-rotate(252deg) brightness(106%) contrast(89%);
}

%filter-color-light-grey {
    filter: invert(38%) sepia(0%) saturate(1%) hue-rotate(271deg) brightness(105%) contrast(95%);
}

%filter-color-lighter-grey {
    filter: invert(52%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(97%) contrast(91%);
}

%filter-color-lightest-grey {
    filter: invert(64%) sepia(0%) saturate(1%) hue-rotate(261deg) brightness(95%) contrast(92%);
}

%filter-color-very-light-grey {
    filter: invert(78%) sepia(0%) saturate(455%) hue-rotate(252deg) brightness(92%) contrast(91%);
}

%filter-color-off-white {
    filter: invert(96%) sepia(0%) saturate(642%) hue-rotate(217deg) brightness(90%) contrast(84%);
}

%filter-color-white {
    filter: brightness(0) invert(1);
}

// Hover states
%filter-color-primary-hover {
    filter: invert(24%) sepia(62%) saturate(2020%) hue-rotate(253deg) brightness(93%) contrast(98%);
}

%filter-color-primary-active {
    filter: invert(11%) sepia(29%) saturate(7235%) hue-rotate(264deg) brightness(112%) contrast(128%);
}

%filter-color-primary-disabled {
    filter: invert(54%) sepia(15%) saturate(1174%) hue-rotate(229deg) brightness(102%) contrast(88%);
}

%filter-disabled {
    filter: invert(82%) sepia(22%) saturate(2%) hue-rotate(315deg) brightness(98%) contrast(86%);
}

%filter-default-hover {
    filter: invert(13%) sepia(59%) saturate(5817%) hue-rotate(279deg) brightness(71%) contrast(122%);
}

// Alert colours
%filter-color-alert-error {
    filter: invert(13%) sepia(97%) saturate(5393%) hue-rotate(317deg) brightness(109%) contrast(107%);
}

%filter-color-alert-warn {
    filter: invert(81%) sepia(14%) saturate(7420%) hue-rotate(329deg) brightness(105%) contrast(101%);
}

%filter-color-alert-info {
    filter: invert(34%) sepia(57%) saturate(2650%) hue-rotate(226deg) brightness(90%) contrast(82%);
}

%filter-color-alert-success {
    filter: invert(54%) sepia(27%) saturate(6497%) hue-rotate(136deg) brightness(94%) contrast(101%);
}
