.mat-mdc-table-sticky {
    background: #fff;
}

.table-ng-row:hover .mat-table-sticky {
    background: #d7d7d7;
}

.table-ng-row:hover {
    background: #d7d7d7;
}

.mat-sort-header-content {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input[type=checkbox].lb-checkbox:before {
        position: static;
    }
}

.table-ng-row {
    &.isSelected {
        $selected-color: #d4cdd9;

        background: $selected-color;

        .mat-mdc-table-sticky {
            background: $selected-color;
        }
    }
}

.mat-sort-header {
    &.right {
        .mat-sort-header-container {
            justify-content: flex-end;

            .mat-sort-header-content {
                text-align: right;
            }
        }
    }

    &.left {
        .mat-sort-header-container {
            justify-content: flex-start;

            .mat-sort-header-content {
                text-align: left;
            }
        }
    }

    &.center {
        .mat-sort-header-container {
            justify-content: center;
            margin-left: 12px;

            .mat-sort-header-content {
                text-align: center;
            }
        }
    }

    &.cdk-keyboard-focused {
        .mat-focus-indicator {
            &:focus {
                outline: none !important;;
                border: 2px dotted var(--color-quinary);
            }
        }
    }
}

.mat-column-actions {
    .mat-sort-header-container {
        display: flex;
        justify-content: flex-end;
    }
}

.mock-table {
    width: calc(100% + 64px);
    margin: 0 -32px;

    th,
    td {
        align-items: center;
        box-sizing: border-box;
        font-size: 14px;
        height: 48px;

        &.left {
            text-align: left;
        }

        &.right {
            text-align: right;
        }

        &:first-of-type {
            padding-left: 32px;
        }

        &:last-of-type {
            padding-right: 32px;
        }
    }

    tr {
        height: auto;
    }

    th {
        border-top: 1px solid var(--table-color-border);
        font-weight: 500;
        height: 50px;
    }

    tr {
        border-bottom: 1px solid var(--table-color-border);

        &:last-of-type {
            border-bottom: 1px solid var(--table-color-border);
        }
    }
}

.mat-column-actions:has(> .status-wrapper) {
    align-items: center;
    display: flex;
    height: 56px;
    justify-content: flex-end;
    width: 180px;
}

.lb-small-table {
    $border-color: var(--input-color-border);

    background: transparent;
    border: 1px solid $border-color;
    border-radius: 5px;
    border-spacing: 0;
    box-sizing: border-box;
    color: var(--color-white);
    overflow: inherit !important;
    width: 100%;

    thead {
        th {
            padding: 5px;
            height: auto;
        }
    }

    tbody {
        tr {
            height: auto;

            &:last-of-type {
                td {
                    padding-bottom: 10px;
                }
            }

            td {
                padding: 5px;
                height: auto;
            }
        }
    }
}

.table-item-content {
    span {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

table.flex-table {
    display: block;

    tr {
        align-items: center;
        display: flex;
        justify-content: space-between;
        max-width: 280px;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 280px;

        td {
            cursor: pointer;
            display: block;
            flex: 1 1;
            max-width: 130px;
            min-width: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: max-width 0.25s linear 0.25s;
            white-space: nowrap;

            &.right {
                padding-left: 10px;
            }

            &:hover {
                flex: auto;
                max-width: fit-content;
            }
        }
    }
}
