@import './../../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import './../../../../node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

app-dashboard {
    owl-carousel-o {
        height: 100%;

        .owl-carousel {
            height: 100%;

            .owl-stage-outer {
                height: calc(100% - 24px); // minus the height of the dots.

                owl-stage {
                    height: 100%;

                    > div {
                        height: 100%;

                        .owl-stage {
                            height: 100%;

                            .owl-item {
                                height: 100%;
                            }
                        }
                    }
                }
            }

            .owl-dots {
                .owl-dot {
                    span {
                        background: var(--color-white);
                        border: 1px solid var(--color-white);
                    }

                    &.active {
                        span {
                            background: none;
                            border: 1px solid var(--color-white);
                        }
                    }

                    &:hover {
                        span {
                            background: var(--color-very-light-grey);
                            border-color: var(--color-very-light-grey);
                        }
                    }
                }
            }
        }
    }
}
