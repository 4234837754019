@import "src/assets/shared/scss/color-filters-for-svgs";

@mixin admonition-block($state-color) {
    table {
        background: $state-color;
        border-radius: 8px;
        margin-bottom: 24px;

        .icon {
            vertical-align: middle;

            .title {
                display: none;
            }

            &:after {
                content: '';
                background-image: url('/assets/shared/icons/notification-solid-exclamation-triangle.svg');
                background-size: 20px;
                background-repeat: no-repeat;
                display: block;
                height: 20px;
                width: 20px;
                margin: 8px;

                @extend %filter-color-white;
            }
        }

        .content {
            color: var(--color-white);
            padding: 8px 8px 8px 0;
        }
    }
}

#help-wrapper {
    > hr {
        display: none;
    }

    // All these classes are from ASCII docs.
    .admonitionblock {
        &.warning {
            @include admonition-block(var(--color-alert-warn));
        }

        &.important {
            @include admonition-block(var(--color-alert-error));
        }

        &.tip, &.note {
            @include admonition-block(var(--color-alert-info));
        }
    }

    .sectionbody {
        margin-bottom: 20px;

        hr {
            margin-bottom: 0;
        }

        .openblock {
            .content {
                padding: 0;
                margin-bottom: 10px;
            }

            .default-value {
                display: inline-block;
                color: #00b391;
                padding-top: 6px;
            }
        }
    }

    .ulist.selected {
        li > p > strong {
            color: var(--color-primary);
        }
    }

    // Style all help not just the new ones.
    ul {
        padding: 0 0 0 15px;
        margin: 0;

        li {
            margin: 0;
        }
    }

    p {
        margin-bottom: 10px;
    }
}
