@import "src/assets/shared/scss/mixins";

[class^="content"] {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    padding: 20px 0 20px 0;
    width: 100%;

    h1, h2, h3, h4 {
        margin-bottom: 0;
    }

    &[class*="-left"] {
        justify-content: flex-start;
    }

    &[class*="-center"] {
        justify-content: center;
    }

    &[class*="-right"] {
        justify-content: flex-end;
    }

    &[class*="-space-between"] {
        justify-content: space-between;
    }

    &[class*="-space-evenly"] {
        justify-content: space-evenly;
    }

    &[class*="-top"] {
        align-items: baseline; // Used instead of flex-start so the element aligns correctly.
    }

    &[class*="-middle"] {
        justify-content: center;
    }

    &[class*="-bottom"] {
        align-items: flex-end;
    }

    &[class*="-vertical"] {
        flex-direction: column;

        &[class*="-left"] {
            align-items: flex-start;
        }

        &[class*="-right"] {
            align-items: flex-end;
        }
    }

    &[class*="-full-width"] {
        width: 100%;
    }

    &[class*="-half-width"] {
        width: 50%;
    }

    [class^="content"],
    &.no-padding {
        padding: 0 !important;
    }
}

hr {
    border: 0;
    border-top: 1px solid var(--color-very-light-grey);
}

.page-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// Used with new tables to force a button to sit inline with table search.
.inline-with-table-search {
    position: absolute;
    right: 32px;
}

.page-loader {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;

    &:after {
        @include loading-indicator(30px);
    }
}

@media only screen and (min-width: 1224px) {
    [class^="content"] {
        padding: 10px 0 20px 0;
    }
}
