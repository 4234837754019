@import './variables';
@import './color-filters-for-svgs.scss';

h1, h2, h3, h4 {
    color: var(--text-color-header);
    font-family: $font-family-header;
    font-weight: 500;
    margin: 0 0 16px;
}

h5, h6, p, span, a, label, div, table {
    color: var(--text-color-body);
    font-family: $font-family;
}

input, textarea {
    color: var(--input-color-text);
    font-family: $font-family;
}

h1 {
    font-size: 20px;
    line-height: 26px;
}

h2 {
    font-size: 18px;
    line-height: 24px;
}

h3 {
    font-size: 16px;
    line-height: 18px;
}

h4 {
    font-size: 14px;
    line-height: 20px;
}

h5 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
}

h6 {
    font-size: 14px;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin: 8px 0 16px;
}

span {
    font-size: 14px;

    > strong {
        font-weight: 500 !important;
    }
}

ol,
ul {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;

    li {
        margin-bottom: 10px;
    }
}

a,
.link {
    cursor: pointer;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    text-decoration: none;

    &:before {
        border-bottom: $input-border-width-style transparent;
        border-color: inherit;
        bottom: -2px;
        content: '';
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    &:hover {
        &:before {
            border-bottom-width: 2px;
        }
    }

    &.external {
        &::after {
            background-image: url("/assets/centralisedManagement/icons/link.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 10px 10px;
            content: "";
            display: inline-block;
            height: 10px;
            margin-left: 3px;
            width: 10px;
        }
    }

    &.right-arrow {
        &:after {
            background-image: url("/assets/shared/icons/chevron-right-square.svg");
            content: "";
            height: 20px;
            width: 20px;
            position: absolute;
        }
    }

    &.primary {
        color: var(--color-primary);

        &:after {
            @extend %filter-primary;
        }

    }

    &.default {
        color: var(--color-quinary);

        &:after {
            @extend %filter-quinary;
        }

    }

    &.disabled {
        color: var(--input-color-text-disabled);
        cursor: not-allowed;
    }
}

a:focus,
a:focus-visible {
    outline: 2px dotted var(--color-quinary);
}


label {
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.02em;
    margin-bottom: 8px;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;

    tr {
        height: 34px;
    }

    th {
        font-size: 14px;
        font-weight: 500;
        text-align: left;

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }
    }

    td {
        font-size: 14px;
        font-weight: 400;
        text-align: left;

        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }
    }
}

// Element spacing when two elements are next to each other.
input + span {
    padding-left: 15px;
}

img {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5,
    + p,
    + span {
        padding-left: 15px;
    }
}

.note {
    color: var(--text-color-inactive);
    display: block;
    font-size: 12px;
    font-style: italic;
    font-weight: normal;
    padding-top: 5px;

    &.no-padding {
        padding-top: 0;
    }

}

.disabled {
    color: var(--input-color-text-disabled);
    cursor: not-allowed !important;
    font-style: oblique;
}

.routerLink {
    background: transparent;
    color: var(--color-quinary);
    cursor: pointer;
    outline: none;

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        border: 2px dotted var(--color-quinary);
        border-radius: 5px;
        padding-left: 1px;
        padding-right: 1px;
    }

    &:disabled {
        color: var(--text-color-inactive);
        font-style: oblique;
        border: none;
    }
}

.support-link {
    color: var(--color-white);
}

.hint {
    background-color: var(--color-main-grey);
    border-radius: 3px;
    box-shadow: 2px 4px 3px 0 rgba(0, 0, 0, 0.2);
    color: var(--text-color-inverted);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.02em;
    line-height: 12px;
    opacity: 0;
    padding: 4px 10px;
    pointer-events: none;
    position: fixed;
    z-index: 170;
    transition: opacity 150ms ease-in;

    &.show {
        opacity: 1;
    }
}

// Widget helper
.block-title {
    color: var(--color-lighter-grey) !important;
}
