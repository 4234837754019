/**
 * Dot Pulse
 */
@mixin drop-pulse($dot-color, $animation-name: 'pulse') {
    // Set the shape
    .dot-pulse,
    .dot-pulse:before,
    .dot-pulse:after {
        animation: #{$animation-name} 2s infinite linear;
        background-color: $dot-color;
        border-radius: 5px;
        box-shadow: 0 0 0 -2px $dot-color;
        height: 3px;
        width: 3px;
        opacity: 0;
    }

    // Setup the pseudo classes
    .dot-pulse:before,
    .dot-pulse:after {
        content: '';
        display: block;
        position: absolute;
    }

    // Step the animation delays and positions
    .dot-pulse {
        position: relative;
        animation-delay: .25s;

        &:before {
            animation-delay: 0s;
            left: -15px;
        }

        &:after {
            animation-delay: .5s;
            right: -15px;
        }
    }

    @keyframes #{$animation-name} {
        0% {
            background: $dot-color;
            box-shadow: 0 0 0 -5px $dot-color;
            opacity: 0;
        }
        5% {
            background: $dot-color;
            opacity: 1;
        }
        30% {
            background: $dot-color;
            box-shadow: 0 0 0 5px $dot-color;
            opacity: 1;
        }
        60%,
        100% {
            background: $dot-color;
            box-shadow: 0 0 0 -5px $dot-color;
            opacity: 0;
        }
    }
}

/**
 * Add this mixin to something you want to spin.
 */
@mixin spin-this($animation-name: 'spin-this', $speed: 1s) {
    animation: #{$animation-name} #{$speed} linear infinite;

    @keyframes #{$animation-name} {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

@mixin shimmer($animation-name: 'shimmer') {
    background: linear-gradient(-45deg, #efeffb 40%, #fafafa 50%, #efeffb 60%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 750ms linear;

    @keyframes #{$animation-name} {
        to {
            background-position-x: 0%
        }
    }
}
