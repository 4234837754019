@import 'src/assets/shared/scss/color-filters-for-svgs';

img {
    &.block {
        display: block;
    }

    &.disabled {
        @extend %filter-disabled;
    }
}
